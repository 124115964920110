.element.t1__subplatform__gridblock {
  .t1-gridBlock {
    .t1-blockHeader {
      @apply pb-10;
    }

    .t1-gridBlock-components {
      @apply flex flex-wrap -mx-4;

      .t1-gridBlock-component {
        @apply p-4 flex flex-col text-left;

        .t1-blockComponent {
          @apply flex-auto flex flex-col-reverse bg-mono-100 text-mono-textBase;

          .t1-blockComponent-contentWrapper {
            @apply p-4 flex flex-col flex-auto;

            .t1-blockComponent-content {
              @apply flex-auto;
            }
          }

          .t1-blockComponent-title {
            @apply mb-4;

            h5 {
              @apply mb-0;
            }

            h4 {
              @apply mb-0 text-2xl xl:text-3xl;
            }

            &:after {
              display: none;
            }
          }

          &.t1-threeqVideoTextComponent {
            .t1-threeqVideoTextComponent-video {
              @apply flex-none;
            }
          }

          &.t1-youtubeTextComponent {
            .t1-youtubeTextComponent-video {
              @apply flex-none;
            }
          }

          &.t1-embeddedVideoComponent {
            .t1-embedVideoComponent-video {
              @apply flex-none;
            }
          }
        }
      }
    }
  }
}
