.t1-blockComponent {
  .t1-blockComponent-title {
    @apply flex flex-col;

    &:after {
      @apply relative block bg-primary bottom-0 my-7;
      content: "";
      width: 130px;
      height: 1px;
    }

    h4 {
      @apply mt-0 text-3xl sm:text-3xl xl:text-5xl;
    }

    h5 {
      @apply mb-5;
    }

    &.t1-blockComponent-title--noSeparator {
      @apply mb-7;

      &:after {
        @apply hidden;
      }
    }
  }

  .t1-blockComponent-content {
    @apply leading-normal;

    p:first-child {
      @apply mt-0;
    }

    p:last-child {
      @apply mb-0;
    }
  }
}
