@id: 'jm9ccc';

@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.eot?@{id}');
  src: url('../../fonts/icomoon.eot?@{id}#iefix') format('embedded-opentype'),
  url('../../fonts/icomoon.ttf?@{id}') format('truetype'),
  url('../../fonts/icomoon.woff?@{id}') format('woff'),
  url('../../fonts/icomoon.svg?@{id}#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.icon-r-90, &.icon-r-180, &.icon-r-270 {
    &:before {
      display: inline-block;
    }
  }
  &.icon-r-90:before {
    transform: rotate(90deg);
  }
  &.icon-r-180:before {
    transform: rotate(180deg);
  }
  &.icon-r-270:before {
    transform: rotate(270deg);
  }
}

.icon-google_login .path1:before {
  content: "\e939";
  color: rgb(66, 133, 244);
}
.icon-google_login .path2:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.icon-google_login .path3:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(251, 188, 5);
}
.icon-google_login .path4:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(234, 67, 53);
}
.icon-takt1_red_loop:before {
  content: "\e936";
}
.icon-takt1_quote:before {
  content: "\e923";
}
.icon-takt1_lock:before {
  content: "\e925";
}
.icon-takt1_google:before {
  content: "\e926";
}
.icon-takt1_twitter:before {
  content: "\e927";
}
.icon-takt1_register_check:before {
  content: "\e928";
}
.icon-takt1_soloist:before {
  content: "\e924";
}
.icon-takt1_accordion_filter_arrow:before {
  content: "\e900";
}
.icon-takt1_user:before {
  content: "\e91e";
}
.icon-takt1_search:before {
  content: "\e91f";
}
.icon-takt1_arrow:before {
  content: "\e920";
}
.icon-takt1_facebook:before {
  content: "\e921";
}
.icon-takt1_logo:before {
  content: "\e922";
}
.icon-takt1_accordion_section_arrow:before {
  content: "\e901";
}
.icon-takt1_accordion_section_audio:before {
  content: "\e902";
}
.icon-takt1_accordion_section_video:before {
  content: "\e903";
}
.icon-takt1_accordion_small_arrow:before {
  content: "\e904";
}
.icon-takt1_advertising:before {
  content: "\e905";
}
.icon-takt1_arte:before {
  content: "\e906";
}
.icon-takt1_audio:before {
  content: "\e907";
}
.icon-takt1_button_back:before {
  content: "\e908";
}
.icon-takt1_button_facebook:before {
  content: "\e909";
}
.icon-takt1_button_list:before {
  content: "\e90a";
}
.icon-takt1_cancel:before {
  content: "\e90b";
}
.icon-takt1_check:before {
  content: "\e90c";
}
.icon-takt1_composer:before {
  content: "\e90d";
}
.icon-takt1_concert_guide:before {
  content: "\e90e";
}
.icon-takt1_conductor:before {
  content: "\e90f";
}
.icon-takt1_date:before {
  content: "\e910";
}
.icon-takt1_ensemble:before {
  content: "\e911";
}
.icon-takt1_expert:before {
  content: "\e912";
}
.icon-takt1_filter:before {
  content: "\e913";
}
.icon-takt1_info:before {
  content: "\e914";
}
.icon-takt1_like:before {
  content: "\e915";
}
.icon-takt1_link:before {
  content: "\e916";
}
.icon-takt1_live:before {
  content: "\e917";
}
.icon-takt1_location:before {
  content: "\e918";
}
.icon-takt1_note_large:before {
  content: "\e919";
}
.icon-takt1_note_small:before {
  content: "\e91a";
}
.icon-takt1_story:before {
  content: "\e91b";
}
.icon-takt1_video:before {
  content: "\e91c";
}
.icon-takt1_youtube:before {
  content: "\e91d";
}
.icon-phone2:before {
  content: "\e937";
}
.icon-keyboard_arrow_down:before {
  content: "\e930";
}
.icon-menu:before {
  content: "\e931";
}
.icon-cloud_download:before {
  content: "\e92b";
}
.icon-more_horiz:before {
  content: "\e92c";
}
.icon-chrome_reader_mode:before {
  content: "\e92d";
}
.icon-amazon:before {
  content: "\e935";
}
.icon-brand:before {
  content: "\e935";
}
.icon-spinner2:before {
  content: "\e933";
}
.icon-loading2:before {
  content: "\e933";
}
.icon-loading-wheel2:before {
  content: "\e933";
}
.icon-busy2:before {
  content: "\e933";
}
.icon-wait2:before {
  content: "\e933";
}
.icon-video-camera:before {
  content: "\e938";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-eye-slash:before {
  content: "\e932";
}
.icon-eye:before {
  content: "\e934";
}
.icon-chain:before {
  content: "\e92e";
}
.icon-link:before {
  content: "\e92e";
}
.icon-paper-plane-o:before {
  content: "\e92f";
}
.icon-send-o:before {
  content: "\e92f";
}
.icon-wrench:before {
  content: "\e929";
}
.icon-wikipedia-w:before {
  content: "\e92a";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-star-full:before {
  content: "\e9d9";
}

.icon-takt1_devices:before {
  content: "\e93d";
}

.icon-takt1_notification:before {
  content: "\ea08";
}

.icon-takt1_gift:before {
  content: "\e99f";
}

.icon-sphere:before {
  content: "\e9c9";
}
.icon-takt1_swr:before {
  content: "\e93e";
}
