header {
  @apply fixed top-0 left-0 w-full bg-mono-100;
  z-index: 2000;

  &.t1-mainHeader--cropped {
    transition: all 500ms ease-in-out;
  }

  &.t1-mainHeader--cropped {
    > .t1-container {
      @apply pb-8;
    }

    &.t1-mainHeader--hasTopHeader {
      > .t1-container {
        @apply pb-12;
      }
    }

    &:not(.t1-mainHeader--mobileMenuVisible) {
      clip-path: polygon(0 0, 100% 0, 100% 70%, calc(5% + 75px + theme('padding.2')) 100%, 0 70%);

      @media screen and (min-width: theme('screens.2xl')) {
        clip-path: polygon(0 0, 100% 0, 100% 70%, calc(((100% - 1280px) / 2) + 75px + theme('padding.2')) 100%, 0 70%);
      }
    }
  }

  > .t1-container {
    @apply flex relative;
  }

  .t1-mainHeader-wrapper {
    @apply flex-auto flex flex-col justify-between;
  }

  .logo {
    @apply p-4 flex-none;

    img {
      width: 120px;
    }
  }

  &.t1-mainHeader-logo--medium {
    .logo {
      img {
        width: 150px;
      }
    }
  }

  .t1-mainHeader-main {
    @apply py-4 flex items-center justify-between;

    .t1-mainHeader-main-right {
      @apply ml-auto;
    }
  }

  &.t1-mainHeader--hasTopHeader {
    .t1-mainHeader-main {
      @apply items-start;
    }
  }

  .t1-mainNav {
    @apply flex-auto pr-8;

    ul {
      @apply flex;
    }

    li {
      @apply uppercase font-semibold text-lg mx-2;

      a {
        @apply transition-colors;
      }
    }
  }

  .t1-sponsors {
    img {
      max-width: 100px;
      max-height: 50px;
    }
  }

  &.t1-mainHeader--logoOverlayEnabled {
    .t1-mainHeader-wrapper {
      /* Logo with + logo padding + main nav left padding */
      padding-left: calc(120px + theme('padding.4') + theme('padding.8'));
    }

    &.t1-mainHeader-logo--medium {
      .t1-mainHeader-wrapper {
        /* Logo with + logo padding + main nav left padding */
        padding-left: calc(150px + theme('padding.4') + theme('padding.8'));
      }
    }

    .logo {
      @apply absolute left-0 top-0 z-10 bg-mono-100;
    }
  }

  .t1-mobileNav {
    @apply hidden;
  }

  @media screen and (max-width: calc(theme('screens.lg') - 1px)) {
    .t1-mainHeader-top {
      @apply hidden;
    }

    .t1-mainHeader-main {
      > .t1-mainNav, .t1-mainHeader-main-right {
        @apply hidden;
      }
    }

    .t1-mobileNav {
      @apply block;
    }

    > .t1-container {
      @apply items-center
    }
  }
}
