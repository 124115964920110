body {
  font-family: var(--font-highlight);

  .t1-defaultPageContent {
    font-family: var(--font-base);

    h1, h2, h3, h4, h5, h6 {
      font-family: var(--font-highlight);
    }
  }

  .element.t1__subplatform__heroblock {
    .t1-heroSlide {
      .t1-heroSlide-overlay {
        > div {
          .t1-blockComponent-content {
            font-family: var(--font-base);
          }
        }
      }
    }
  }

  .t1-videoBlock .t1-videoBlock-description {
    font-family: var(--font-base);
  }

  .t1-editorContent {
    font-family: var(--font-base);
  }
}
