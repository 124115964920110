.element.t1__subplatform__imagegalleryblock {
  .t1-imageGalleryBlock-components {
    @apply flex flex-wrap;

    .t1-linkedImageComponent {
      @apply p-6;
    }
  }

  &.element--align-center {
    .t1-imageGalleryBlock-components {
      @apply justify-center;
    }
  }
}
