.element.t1__subplatform__rowblock {
  .t1-blockHeader {
    @apply pb-20;
  }

  .t1-blockComponent {
    @apply flex items-center flex-col-reverse;

    &:not(:last-child) {
      @apply mb-20;
    }

    .t1-imageTextComponent-image, .t1-youtubeTextComponent-video, .t1-threeqVideoTextComponent-video, .t1-audioTextComponent-image {
      @apply mb-3;
    }

    .t1-threeqVideoTextComponent-video {
      width: 100%;
    }

    @media screen and (min-width: theme('screens.md')) {
      @apply -mx-6;

      > div {
        @apply px-6;
      }

      .t1-blockComponent-contentWrapper {
        @apply w-1/2;
      }

      .t1-imageTextComponent-image, .t1-youtubeTextComponent-video, .t1-threeqVideoTextComponent-video, .t1-audioTextComponent-image {
        @apply w-1/2 mb-0;
      }
    }
  }

  @media screen and (min-width: theme('screens.md')) {
    .t1-rowBlock-layout {
      &.content-left {
        .t1-blockComponent {
          @apply flex-row text-right;

          .t1-audioTextComponent-audio {
            @apply flex justify-end;
          }

          .t1-blockComponent-title {
            @apply items-end;
          }
        }
      }

      &.content-right {
        .t1-blockComponent {
          @apply text-left flex-row-reverse;
        }
      }

      &.alternating {
        .t1-blockComponent {
          @apply flex-row;
        }

        .t1-blockComponent:nth-child(2n - 1) {
          @apply text-right;

          .t1-audioTextComponent-audio {
            @apply flex justify-end;
          }

          .t1-blockComponent-title {
            @apply items-end;
          }
        }

        .t1-blockComponent:nth-child(2n) {
          @apply text-left flex-row-reverse;
        }
      }
    }
  }
}
