footer {
  .t1-footerMenu {
    nav > ul {
      @media screen and (min-width: theme('screens.xs')) {
        column-count: 2;
        column-gap: theme('spacing.20');
      }
    }
  }

  .t1-footerLogo {
    img {
      width: 120px;
    }
  }
}
