@import "row-block.css";
@import "grid-block.css";
@import "hero-block.css";
@import "image-gallery-block.css";
@import "audio-block.css";
@import "components/index.css";

.element {
  .elementInner {
    @apply t1-container;

    &.element--noContainer {
      @apply w-full max-w-full;
    }
  }

  &.element--align-center {
    @apply text-center;

    .t1-blockTitle {
      @apply items-center;
    }
  }

  &.element--align-right {
    @apply text-right;

    .t1-blockTitle {
      @apply items-end;
    }
  }

  .t1-blockTitle {
    @apply flex flex-col;

    &:after {
      @apply relative block bg-primary bottom-0 my-7;
      content: "";
      width: 130px;
      height: 1px;
    }

    h2 {
      @apply mt-0;
    }

    h5 {
      @apply mb-5;
    }
  }

  .t1-blockHeader.t1-blockHeader--noSeparator .t1-blockTitle {
    &:after {
      display: none;
    }
  }

  .t1-blockHeader {
    @apply pb-4;
  }
}
