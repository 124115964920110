$spacing-left: 152px; /* Logo width (120) + padding */
$spacing-bottom: 100px;

.element.t1__subplatform__heroblock {
  @apply w-full max-w-full;

  .elementInner {
    @apply w-full max-w-full;
  }

  @media screen and (min-width: theme('screens.xl')) {
    width: calc(100vw - ((100vw - theme('screens.xl')) / 2));
    margin-left: calc((100vw - theme('screens.xl')) / 2 - var(--scrollbar-width));

    .VueCarousel-slide {
      padding-left: $spacing-left;
      margin-bottom: $spacing-bottom;
    }
  }

  .t1-heroSlide {
    @apply relative;

    .t1-heroSlide-image {
      @apply w-full h-0 bg-cover bg-no-repeat z-10;

      /* 16/9 */
      padding-bottom: 56.25%;
    }

    .t1-heroSlide-videoContainer {
      @apply w-full h-0 z-10;

      /* 16/9 */
      padding-bottom: 56.25%;
    }

    .t1-heroSlide-overlay {
      @apply p-8 bg-mono-100 bg-opacity-50 backdrop-filter backdrop-blur-2xl z-20;
      border-radius: 40px;

      > div {
        @apply relative z-50 text-mono-textBase;

        .t1-blockComponent-title {
          @apply text-3xl sm:text-4xl xl:text-6xl leading-tight xl:leading-normal pb-4;
          font-family: var(--font-highlight);
        }

        .t1-blockComponent-content {
          @apply text-base;
        }
      }

      @media screen and (max-width: calc(theme('screens.xl') - 1px)) {
        @apply mx-auto -mt-10;
        width: 90%;
      }

      @media screen and (min-width: theme('screens.xl')) {
        @apply absolute;
        left: -$spacing-left;
        bottom: -$spacing-bottom;
        width: 500px;
      }
    }
  }

  .VueCarousel {
    .VueCarousel-navigation {
      @apply hidden;

      @media screen and (min-width: theme('screens.xl')) {
        @apply block absolute right-0 flex;
        bottom: $spacing-bottom;

        .VueCarousel-navigation-button {
          @apply relative flex justify-center items-center bg-mono-100 bg-opacity-50 backdrop-filter backdrop-blur-2xl w-20 h-20;
          @apply m-0 left-0 right-auto transform-none outline-none !important;

          i {
            @apply text-3xl absolute flex justify-center items-center transition-colors text-mono-100 border-mono-100 w-3/4 h-3/4 rounded-full;
            border: 1px solid;

            &:before {
              @apply relative block;
            }
          }

          &.VueCarousel-navigation--disabled {
            @apply opacity-100 bg-opacity-25;
          }

          &.VueCarousel-navigation-prev {
            i:before {
              left: -2px;
              transform: rotate(180deg);
            }
          }

          &.VueCarousel-navigation-next {
            i:before {
              right: -2px;
            }
          }
        }
      }
    }

    .VueCarousel-pagination {
      @apply absolute right-2 w-auto;
      top: calc((100% - $spacing-bottom) / 2);

      .VueCarousel-dot-container {
        @apply flex flex-col;
        @apply m-0 !important;

        .VueCarousel-dot {
          @apply relative m-1 p-1 outline-none bg-transparent rounded-full !important;
          border: 1px solid theme('colors.mono.100');

          &:after {
            @apply absolute top-1/2 left-1/2 w-2/5 h-2/5 bg-mono-100 transition-opacity opacity-0 transform -translate-y-1/2 -translate-x-1/2 rounded-full;
            content: "";
          }

          &.VueCarousel-dot--active, &:hover {
            &:after {
              @apply opacity-100;
            }
          }
        }
      }

      @media screen and (max-width: calc(theme('screens.xl') - 1px)) {
        @apply top-0 h-0;
        padding-bottom: 56.25%;

        .VueCarousel-dot-container {
          @apply absolute top-0 h-full flex flex-col justify-center;
          width: 30px;
          left: -30px;
        }
      }

      @media screen and (min-width: theme('screens.xl')) {
        @apply transform -translate-y-1/2;
      }
    }
  }
}
