.video-container .t1-videoCookieConsentHint {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 77, 77, var(--tw-bg-opacity));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 1rem;
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: center;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    width: 100%;
  }
    @media (min-width: 640px) {
      .video-container .t1-videoCookieConsentHint .t1-videoCookieConsentHint-description {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
    .video-container .t1-videoCookieConsentHint .t1-videoCookieConsentHint-actions {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
    }
    .video-container .t1-videoCookieConsentHint .t1-videoCookieConsentHint-actions > a {
        margin-top: 0.5rem;
        text-decoration: underline;
      }
