.t1-mediaLibraryCard {
  .t1-mediaLibraryCard-image {
    width: 100%;
    height: 0;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    svg {
      display: none;
    }

    a {
      @apply absolute top-0 left-0 w-full h-full;
    }

    &.t1-mediaLibraryCard--noThumbnail {
      @apply bg-mono-50;
      svg {
        @apply block absolute top-1/2 left-1/2 text-6xl text-mono-100;
        transform: translate(-50%, -50%);
      }
    }
  }

  .t1-mediaLibraryCard-overlay {
    @apply
    absolute
    z-10
    left-0 bottom-0
    w-full
    text-mono-100
    p-3
    transition-colors
    bg-gradient-to-t from-mono-0
    backdrop-filter backdrop-blur-sm
    max-h-full flex flex-col;

    .t1-mediaLibraryCard-overlay-cta {
      @apply rounded-full text-2xl flex items-center justify-center w-10 h-10 flex-shrink-0;
    }

    .t1-mediaLibraryCard-overlay-base {
      @apply flex items-center justify-between flex-none;
    }

    .t1-mediaLibraryCard-overlay-details {
      @apply hidden flex-auto overflow-y-auto;

      li {
        &:first-child {
          @apply mt-2;
        }
        &:last-child {
          @apply mb-2;
        }
        > div:first-child {
          width: 16px;
          i {
            @apply text-xs;
          }
        }
      }
    }
  }

  &:not(.t1-mediaLibraryCard--locked):hover {
    .t1-mediaLibraryCard-overlay {
      background: theme('colors.primary.DEFAULT');
    }
  }

  .t1-mediaLibraryCard-lockedOverlay {
    @apply
    absolute
    z-40
    top-0
    left-0
    w-full
    h-full
    bg-opacity-50
    text-5xl
    flex
    justify-center
    items-center;
  }
}
