.element.t1__subplatform__audioblock {
  &.element--align-center {
    audio {
      @apply mx-auto;
    }
  }

  &.element--align-right {
    audio {
      @apply ml-auto;
    }
  }
}
